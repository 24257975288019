import { environment } from './../../../environments/environment';
import { OrgQueryString } from './common.service';
import { HttpService } from './http.service';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ManageOrganizationService {

  constructor(private httpService: HttpService, private http: HttpClient) { }

  getAll(querString?: OrgQueryString) {
    // const params = this.commonService.createOrgParams(querString);
    return this.httpService.httpGet('/organization/all');
  }

  getAllp(sort: string, order: string, page: number) {
    const requestUrl = (sort) ? `?sort=${sort}&order=${order}&page=${page + 1}` : '';
    return this.httpService.httpGet('/organization/all' + requestUrl);
  }

  addOrganization(organization: any) {
    return this.httpService.httpPost('/organization/create', organization);
  }

  updateOrganization(organization: any) {
    return this.httpService.httpPost('/organization/update', organization);
  }
  changeBulkOrganizationStatus(statuswithIds: any) {
    return this.httpService.httpPost('/organization/change/bulk/status', statuswithIds);
  }
  changeOrganizationStatus(organization: any) {
    return this.httpService.httpPost('/organization/change/status', organization);
  }
  getOrganization(id: string) {
    return this.httpService.httpGet(`/organization/detail/${id}`);
  }

  deleteOrganization(organization: any) {
    return this.httpService.httpPost('/organization/delete', organization);
  }

  validateEmail(orgEmail: any) {
    return this.httpService.httpPost('/organization/validateEmail', orgEmail);
  }

  validateOrg(orgName: any) {
    return this.httpService.httpPost('/organization/validateOrg', orgName);
  }

  filterData(filterValue: number) {
    return this.httpService.httpGet('/organization/filter?area_id=' + filterValue);
  }
  getTasks(org) {
    return this.httpService.httpGet(`/organization/tasks?organization_id=${org.organization_id}`);
  }
  updateTheVideoWatchStatus(videoOrgObj: any) {
    return this.httpService.httpPost('/organization/watch/task/video', videoOrgObj);
  }
  getStepsWatchedByOrganization(org_id: string) {
    return this.httpService.httpGet(`/organization/steps/watched?org_id=${org_id}`);
  }
  checkIfAthleteOrVolunteerAssociatedWithOrganization(organization: any) {
    return this.httpService.httpPost('/organization/check-if-athlete-volunteer/associated', organization);
  }

  sendMail(objectOfIds: any) {
    return this.httpService.httpPost('/organization/send/email', objectOfIds);
  }

  sendSMS(objectOfIds: any) {
    return this.httpService.httpPost('/organization/send/sms', objectOfIds);
  }

  importOrganization(fileobj: File) {
    const apiCreateEndpoint = `${environment.apiUrl}/organization/import`;
    const formData: FormData = new FormData();

    formData.append('fileItem', fileobj, fileobj.name);

    const req = new HttpRequest('POST', apiCreateEndpoint, formData, {
      reportProgress: true
    });
    return this.http.request(req).pipe(
      map(event => this.getEventMessage(event, formData)),
      catchError(this.handleError)
    );

  }

  exportOrganization() {
    return this.httpService.httpGet('/organization/export');
  }

  private getEventMessage(event: HttpEvent<any>, formData) {

    switch (event.type) {

      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);

      case HttpEventType.Response:
        return this.apiResponse(event);

      default:
        return `File "${formData.get('fileItem').name}" surprising upload event: ${event.type}.`;
    }
  }

  private fileUploadProgress(event) {
    const percentDone = Math.round(100 * event.loaded / event.total);
    return { status: 'progress', message: percentDone };
  }

  private apiResponse(event) {
    return event.body;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened. Please try again later.');
  }

}
