import { Component } from '@angular/core';

@Component({
  selector: 'app-print-roster',
  templateUrl: './print-roster.component.html',
  styleUrls: ['./print-roster.component.scss']
})
export class PrintRosterComponent {

  constructor() { }


}
