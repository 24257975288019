import { Component, OnInit } from '@angular/core';
import { PrintService } from 'src/app/shared/services/print.service';
import { ActivatedRoute } from '@angular/router';
import { Volunteers } from 'src/app/models';
import { Volunteerexecutivehold } from 'src/app/models/volunteerexecutivehold';
import { VolunteerHolds } from 'src/app/models/volunteer-holds';
import { TransactionList } from 'src/app/models/transaction-list';

@Component({
  selector: 'app-print-volunteer-transaction',
  templateUrl: './print-volunteer-transaction.component.html',
  styleUrls: ['./print-volunteer-transaction.component.scss']
})
export class PrintVolunteerTransactionComponent implements OnInit {
   displayedColumns = ['invoiceNumber','transId','created_at','amount', 'paidby', 'organizationName'];
  participants: Array<TransactionList>;
  constructor(private route: ActivatedRoute, private printService: PrintService) { }

  ngOnInit() {
    const participantIds: number[] = this.route.snapshot.params['participantIds'].split(',');

    this.printService.volunteerTransaction({ ids: participantIds }).subscribe(
      (participants: any) => {
        this.participants = participants.data;
        this.printService.onDataReady();
      });
  }
 
}
