import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrintRosterComponent } from './print-roster.component';
import { PrintAthleteComponent } from './print-athlete/print-athlete.component';
import { PrintVolunteerComponent } from './print-volunteer/print-volunteer.component';
import { PrintVolunteerTransactionComponent } from './print-volunteer-transaction/print-volunteer-transaction.component';
import { PrintAthleteTransactionComponent } from './print-athlete-transaction/print-athlete-transaction.component';

const routes: Routes = [
  {
    path: 'print',
    outlet: 'print',
    component: PrintRosterComponent,
    children: [
      { path: 'athlete-roster/:invoiceIds', component: PrintAthleteComponent },
      { path: 'volunteer-roster/:invoiceIds', component: PrintVolunteerComponent },
      { path: 'volunteer-transaction/:invoiceIds', component: PrintVolunteerTransactionComponent },
       { path: 'athlete-transaction/:invoiceIds', component: PrintAthleteTransactionComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrintRosterRoutingModule { }
