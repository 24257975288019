import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private matSnackBar: MatSnackBar) { }
  open(message: string, action: string, duration?: number) {
    this.matSnackBar.open(message, action, { duration: 2000 });
  }

}
