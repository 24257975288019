import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ManageAreaService {

  constructor(private httpService: HttpService) { }
  getAll(status?: any) {
    return this.httpService.httpGet('/area/all');
  }

  getAllActive() {
    return this.httpService.httpGet('/area/all-active');
  }

  addArea(area) {
    return this.httpService.httpPost('/area/create', area);
  }
  updateArea(area) {
    return this.httpService.httpPost('/area/update', area);
  }

  changeAreaStatus(area) {
    return this.httpService.httpPost('/area/change/status', area);
  }
  deleteArea(area) {
    return this.httpService.httpPost('/area/delete', area);
  }


}
