import { VolunteersService } from '../services/volunteers.service';
import { Directive } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, FormGroup } from '@angular/forms';
import { AtheleteService } from '../services/athelete.service';

@Directive({
  selector: '[appCustomValidator]'
})
export class CustomValidatorsDirective {

  constructor() { }

  static vaildEmail(c: FormControl): ValidationErrors {
    const email: string = c.value;
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email && email.length > 5 && !reg.test(email)) {
      return { vaildEmail: true };
    }
    return null;
  }

  static vaildDob(c: FormControl): ValidationErrors {
    const dob: string = c.value;
    if (dob) {
      const dobd: string = dob.replace(/[\W_]/g, '');
      if (dobd.length !== 8) {
        return { validDob: true };
      }
      return null;
    }
    return null;
  }

  static isvEmailExist(volunteerService: VolunteersService, vEmail?: string) {
    return (control: AbstractControl) => {
      vEmail = (vEmail) ? vEmail : control.value;
      return volunteerService.validateEmail({ email: control.value, oldEmail: vEmail });
    };
  }
  static isAthprntEmailExist(athleteService: AtheleteService, apEmail?: string) {
    return (control: AbstractControl) => {
      apEmail = (apEmail) ? apEmail : control.value;
      return athleteService.validateEmail({ email: control.value, oldEmail: apEmail });
    };
  }
  public static validPassword(control: FormControl): ValidationErrors {
    if (control.value == null) {
      return null;
    } else if (control.value != null) {
      if (control.value.length < 6) {
        return null;
      }
    }
    const hasNumber = /\d/.test(control.value);
    const hasLower = /[a-z]/.test(control.value);
    const hasUpper = /[A-Z]/.test(control.value);
    const valid = hasNumber && hasLower && hasUpper;
    if (!valid) {
      return { validPassword: true };
    }

    return null;
  }

  static checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      const passwordInput = group.controls[passwordKey];
      const passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }
  static chekcIfSpecialCharsExists(value) {
    let format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/;
    return format.test(value);
  }
  public static checkIfEnteredValueLessThanEqual100(control: FormControl): ValidationErrors {
    if (control.value == null) {
      return [];
    } else if (control.value && /[a-z]/i.test(control.value)) {
      return { enterOnlyNumber: true };
    } else if (CustomValidatorsDirective.chekcIfSpecialCharsExists(control.value)) {
      return { invalidEntry: true };
    }
    else if (control.value.toString().split('.').length > 2) {
      return { invalidEntry: true };
    }
    else if (control.value && isNaN(parseFloat(control.value))) {
      return { itsNotANumber: true };
    }
    else if (control.value && parseFloat(control.value) > 100) {
      return { invalidValue: true };
    } else {
      return [];
    }
  }
  public static checkIfEnteredValueLessThanEqual150(control: FormControl): ValidationErrors {
    if (control.value == null) {
      return [];
    } else if (control.value && /[a-z]/i.test(control.value)) {
      return { enterOnlyNumber: true };
    } else if (CustomValidatorsDirective.chekcIfSpecialCharsExists(control.value)) {
      return { invalidEntry: true };
    }
    else if (control.value.toString().split('.').length > 2) {
      return { invalidEntry: true };
    }
    else if (control.value && isNaN(parseFloat(control.value))) {
      return { itsNotANumber: true };
    } else if (control.value && parseFloat(control.value) > 150) {
      return { invalidValue: true };
    } else {
      return [];
    }
  }
}
