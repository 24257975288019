import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintService } from 'src/app/shared/services/print.service';
import { Athlete, AthleteHolds } from 'src/app/models';
import { Athleteexecutivehold } from 'src/app/models/athleteexecutivehold';

@Component({
  selector: 'app-print-athlete',
  templateUrl: './print-athlete.component.html',
  styleUrls: ['./print-athlete.component.scss']
})
export class PrintAthleteComponent implements OnInit {
  participants: Array<Athlete>;

  constructor(private route: ActivatedRoute, private printService: PrintService) {
  }

  ngOnInit() {
    const participantIds: number[] = this.route.snapshot.params['participantIds'].split(',');
    this.printService.athleteRoster({ ids: participantIds }).subscribe((participants: any) => {
      this.participants = participants.data;
      this.participants.forEach((element: Athlete) => {
        element.hasHold = this.hasAthleteHolds(element);
        element.hasCOVID = this.hasCOVID(element);
      });
      this.printService.onDataReady();
    });

  }
  hasAthleteHolds(element: Athlete) {
    if (this.haveAtheleteHolds(element.athlete_hold) || this.checkAthleteexecutiveholds(element.athleteexecutiveholds)) {
      return true;
    } else {
      return false;
    }
  }
  haveAtheleteHolds(athlete_hold: AthleteHolds) {
    if (athlete_hold && athlete_hold.isPhotoUploaded && athlete_hold.isPaymentReceived && athlete_hold.isBirthCertificate
      && athlete_hold.isHitcheckCompleted
      && athlete_hold.isContractSigned) {
      return false;
    } else {
      return true;
    }
  }
  checkAthleteexecutiveholds(athleteexecutiveholds: Array<Athleteexecutivehold>) {
    if (athleteexecutiveholds.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  hasCOVID(element: Athlete) {
    if (element.athleteexecutiveholds && element.athleteexecutiveholds.length) {
      return element.athleteexecutiveholds.find(a => a.cleared === false && a.executivehold.id === 5) ? true : false;
    } else {
      return false;
    }
  }
}
