import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { environment } from '../../../environments/environment';
import { OrganizationAuthService } from 'src/app/shared/services';
import { UserLoginInfo } from 'src/app/models/organization';

@Component({
  selector: 'app-export-rosters',
  templateUrl: './export-rosters.component.html',
  styleUrls: ['./export-rosters.component.scss']
})
export class ExportRostersComponent implements OnInit {
  currentUser: UserLoginInfo;

  constructor(private bottomSheetRef: MatBottomSheetRef<ExportRostersComponent>, private orgAuthService: OrganizationAuthService) {
    this.currentUser = this.orgAuthService.currentUserValue;
  }

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  ngOnInit() {}
  downloadAthletes($event: any) {
    $event.preventDefault();
    const url = environment.apiUrl + '/athlete/download?x-tyfa-token=' + this.currentUser.token;
    window.open(url, '_self');
  }
  downloadVolunteers($event: any) {
    $event.preventDefault();
    const url = environment.apiUrl + '/volunteer/download?x-tyfa-token=' + this.currentUser.token;
    window.open(url, '_self');
  }
}
