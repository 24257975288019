<div class="transaction_list">
<div class="athelete_table">
    <div class="table_inner mat-elevation-z1">
      <mat-table #table [dataSource]="participants" matSort aria-label="Elements">
        
        <ng-container matColumnDef="invoiceNumber">
          <mat-header-cell *matHeaderCellDef >Invoice Number</mat-header-cell>
          <mat-cell *matCellDef="let row" >
            <a class="athlete_name" >{{row.invoiceNumber}}</a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="transId">
          <mat-header-cell *matHeaderCellDef >Transaction ID
          </mat-header-cell>
          <mat-cell *matCellDef="let row" >
            <span>{{ (row.transId)?row.transId:'---' }}</span>
          </mat-cell>
        </ng-container> 

        <ng-container matColumnDef="created_at">
          <mat-header-cell *matHeaderCellDef >Invoice Date</mat-header-cell>
          <mat-cell *matCellDef="let row" >
            {{ row.created_at | date: "MMM d, y h:mm a" }}
          </mat-cell>
        </ng-container> 

         <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef >Amount Paid</mat-header-cell>
          <mat-cell *matCellDef="let row" >
            {{ row.amount | currency }}
          </mat-cell>
        </ng-container> 

        <ng-container matColumnDef="paidby">
          <mat-header-cell *matHeaderCellDef >Payment From
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.paidby | titlecase }}
          </mat-cell>
        </ng-container>

         <ng-container matColumnDef="organizationName">
          <mat-header-cell *matHeaderCellDef>Organization Name
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.volunteer_transactions[0]?.volunteer?.organization?.organizationName }}
          </mat-cell>
        </ng-container> 

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>

    </div>
    <!-- <mat-paginator [length]="resultsLength" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->

  </div>
</div>