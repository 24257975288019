import { SystemConfiguration } from '../../models/system-configuration';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { DialogsService } from 'src/app/shared/services/dialogs.service';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  colors = ['#126385', '#1f82a5', '#2194b8', '#2ea6ca', '#38b5d7', '#4ec0da', '#67cbdd'];
  someMoreRandomColors = ['#8ddae5', '#b8e9ee', '#e3f6f8', '#275674', '#327696', '#3687a9', '#4099bb', '#56b3ce', '#6bc0d4', '#265673', '#1c5376'];
  constructor(private dialogsService: DialogsService, private titleService: Title) { }
  createOrgParams(queryString: OrgQueryString) {
    let params = new HttpParams();

    if (queryString.areaId) {
      params = params.append('area_id', queryString.areaId.toString());
    }

    if (queryString.filter) {
      params = params.append('filter', queryString.filter);
    }

    if (queryString.sortOrder) {
      params = params.append('sortOrder', queryString.sortOrder);
    }

    if (queryString.pageNumber) {
      params = params.append('pageNumber', queryString.pageNumber.toString());
    }

    if (queryString.pageSize) {
      params = params.append('pageSize', queryString.pageSize.toString());
    }
    return params;
  }
  setTitle(title?: string) {
    this.titleService.setTitle(title ? title : 'TYFA Organizations');
  }
  calculateAgeBySystemConfiguration(systemConfig: SystemConfiguration, userDOB: string) {
    const dobd: string = userDOB.replace(/[\W_]/g, '');
    if (dobd.length === 8) {

      const sysdate = moment(systemConfig.asOfDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).date();
      const sysmonth = moment(systemConfig.asOfDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).month();
      const sysyear = moment(systemConfig.asOfDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).year();

      const dobDate = moment(userDOB, ['MM-DD-YYYY', 'YYYY-MM-DD']);
      const prtdate = moment(dobDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).date();
      const prtmonth = moment(dobDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).month();
      const prtyear = moment(dobDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).year();
      const prtDob = moment([prtyear, prtmonth, prtdate]);
      const sysconfDate = moment([sysyear, sysmonth, sysdate]);
      return sysconfDate.diff(prtDob, 'year'); // 1}
    } else {
      return 0;
    }
  }
  confirmToDelete(data, callback) {
    this.dialogsService.confirm('Confirmation', data.message).subscribe((res) => {
      callback(res);
    }, (error) => {
      callback(false);
    });
  }

  randomString(value: any) {
    if (value.bracket.description.toLowerCase() === 'tykes') { return this.colors[0]; }
    if (value.bracket.description.toLowerCase() === 'rookies') { return this.colors[1]; }
    if (value.bracket.description.toLowerCase() === 'juniors') { return this.colors[2]; }
    if (value.bracket.description.toLowerCase() === 'mascots') { return this.colors[3]; }
    if (value.bracket.description.toLowerCase() === 'flag') { return this.colors[4]; }
    if (value.bracket.description.toLowerCase() === 'seniors') { return this.colors[5]; }
    if (value.bracket.description.toLowerCase() === 'varsity') {
      return this.colors[6];
    } else { return this.someMoreRandomColors[Math.floor(Math.random() * 10)]; }
  }
  calculateCrossoverDateDifference(systemConfig: SystemConfiguration) {
    const nowdate = moment(new Date(), ['MM-DD-YYYY', 'YYYY-MM-DD']).date();
    const nowmonth = moment(new Date(), ['MM-DD-YYYY', 'YYYY-MM-DD']).month();
    const nowyear = moment(new Date(), ['MM-DD-YYYY', 'YYYY-MM-DD']).year();
    const now = moment([nowyear, nowmonth, nowdate]);
    const duedate = moment(systemConfig.crossoverLateFeeDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).date();
    const duemonth = moment(systemConfig.crossoverLateFeeDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).month();
    const dueyear = moment(systemConfig.crossoverLateFeeDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).year();
    const end = moment([dueyear, duemonth, duedate]); // another date
    return now.diff(end, 'hour');
  }
  calculateLockTimeDifference(lockDate: any) {
    const nowdate = moment(new Date(), ['MM-DD-YYYY', 'YYYY-MM-DD']).date();
    const nowmonth = moment(new Date(), ['MM-DD-YYYY', 'YYYY-MM-DD']).month();
    const nowyear = moment(new Date(), ['MM-DD-YYYY', 'YYYY-MM-DD']).year();
    const now = moment([nowyear, nowmonth, nowdate]);
    const duedate = moment(lockDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).date();
    const duemonth = moment(lockDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).month();
    const dueyear = moment(lockDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).year();
    const end = moment([dueyear, duemonth, duedate]); // another date
    return now.diff(end, 'hour');
  }
  calculateTimeDifference(systemConfig: SystemConfiguration) {
    const nowdate = moment(new Date(), ['MM-DD-YYYY', 'YYYY-MM-DD']).date();
    const nowmonth = moment(new Date(), ['MM-DD-YYYY', 'YYYY-MM-DD']).month();
    const nowyear = moment(new Date(), ['MM-DD-YYYY', 'YYYY-MM-DD']).year();
    const now = moment([nowyear, nowmonth, nowdate]);
    const duedate = moment(systemConfig.paymentDueDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).date();
    const duemonth = moment(systemConfig.paymentDueDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).month();
    const dueyear = moment(systemConfig.paymentDueDate, ['MM-DD-YYYY', 'YYYY-MM-DD']).year();
    const end = moment([dueyear, duemonth, duedate]); // another date
    return now.diff(end, 'hour');
  }
  getSysConfigYearSeason(sysconfig: SystemConfiguration) {
    const month = moment().month() + 1;
    const whereStatement: any = { season: null, year: null };
    const springPeriodFrom = new Date(sysconfig.springPeriodFrom);
    const fallPeriodFrom = new Date(sysconfig.fallPeriodFrom);
    if (sysconfig) {
      if (month === 1 || month === 2 || month === 3 || month === 4 || month === 5) {
        whereStatement.season = 'Spring';
        whereStatement.year = springPeriodFrom.getFullYear();
      } else if (month === 6 || month === 7 || month === 8 || month === 9 || month === 10 || month === 11 || month === 12) {
        whereStatement.season = 'Fall';
        whereStatement.year = fallPeriodFrom.getFullYear();
      }
    }
    return whereStatement;
  }
  showDateMonthOfAgeCalculation(systemConfig: SystemConfiguration) {
    if (systemConfig) {
      const date = moment(systemConfig.asOfDate).date();
      const month = moment(systemConfig.asOfDate).month() + 1;
      return month + '/' + date;
    } else {
      return null;
    }
  }
  checkFileFormatIfImageOrPDF(fileNameWithExtension: string) {
    const fileExt = fileNameWithExtension.split('.').pop();
    const fileFormat = ['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png'];
    if (fileExt && fileFormat.find(ex => ex === fileExt)) {
      return {
        isValid: true,
      };
    } else {
      return {
        isValid: false,
        message: `Only ${fileFormat.join(', ')} file formats are supported.`
      };
    }
  }
}
export interface OrgQueryString {
  pageSize?: string;
  pageNumber?: string;
  sortOrder?: string;
  filter: string;
  areaId?: string;
}
