import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  isPrinting = false;

  constructor(private router: Router, private httpService: HttpService) { }

  printAthleteDocument(documentName: string, documentData: number[]) {
    this.isPrinting = true;
    this.router.navigate(['/', { outlets: { 'print': ['print', documentName, documentData.join()] } }]);
  }

  printVolunteerDocument(documentName: string, documentData: number[]) {
    this.isPrinting = true;
    this.router.navigate(['/', { outlets: { 'print': ['print', documentName, documentData.join()] } }]);
  }

  printVolunteerTransactionDocument(documentName: string, documentData: number[]) {
    this.isPrinting = true;
    this.router.navigate(['/', { outlets: { 'print': ['print', documentName, documentData.join()] } }]);
  }

  printAthleteTransactionDocument(documentName: string, documentData: number[]) {
    this.isPrinting = true;
    this.router.navigate(['/', { outlets: { 'print': ['print', documentName, documentData.join()] } }]);
  }

  onDataReady() {
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      this.router.navigate([{ outlets: { print: null } }]);
    }, 1000);
  }

  athleteRoster(athleteIds: any) {
    return this.httpService.httpPost('/athlete/print', athleteIds);
  }

  volunteerRoster(volunteerIds: any) {
    return this.httpService.httpPost('/volunteer/print', volunteerIds);
  }

  volunteerTransaction(transactionIds: any) {
    return this.httpService.httpPost('/volunteer/print-transaction', transactionIds);
  }

  athleteTransaction(transactionIds: any) {
    return this.httpService.httpPost('/athlete/print-transaction', transactionIds);
  }

}
