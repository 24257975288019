import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Router } from '@angular/router';
import { UserLoginInfo } from 'src/app/models/organization';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Base64 } from 'js-base64';
@Injectable({
  providedIn: 'root'
})

export class OrganizationAuthService {

  private currentUserSubject: BehaviorSubject<UserLoginInfo>;
  public currentUser: Observable<UserLoginInfo>;

  constructor(private httpService: HttpService, private router: Router) {
    const bs604de: string = (localStorage.getItem('itIsOow')) ? Base64.decode(localStorage.getItem('itIsOow')) : null;
    this.currentUserSubject = new BehaviorSubject<UserLoginInfo>(JSON.parse(bs604de));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserLoginInfo {
    return this.currentUserSubject.value;
  }

  public set currentUserValue(orguserData: UserLoginInfo) {
    this.currentUserSubject.next(orguserData);
  }

  login(organization: any) {
    return this.httpService.httpPost('/organization/login', organization)
      .pipe(map(async (userData: any) => {
        if (userData.data && userData.data.token) {
          const bs604en: string = await Base64.encode(JSON.stringify(userData.data));
          localStorage.setItem('itIsOow', bs604en);
          this.currentUserValue = userData.data;
        }
        return userData;
      }), catchError(err => throwError(err)));
  }
  proxylogin(organization: any) {
    return this.httpService.httpPost('/organization/proxy-login', organization)
      .pipe(map(async (userData: any) => {
        if (userData.data && userData.data.token) {
          const bs604en: string = await Base64.encode(JSON.stringify(userData.data));
          localStorage.setItem('itIsOow', bs604en);
          this.currentUserValue = userData.data;
        }
        return userData;
      }), catchError(err => throwError(err)));
  }

  volunteerLogin(organization: any) {
    return this.httpService.httpPost('/organization/volunteer-login', organization)
      .pipe(map(async (userData: any) => {
        if (userData.data && userData.data.token) {
          const bs604en: string = await Base64.encode(JSON.stringify(userData.data));
          localStorage.setItem('itIsOow', bs604en);
          this.currentUserValue = userData.data;
        }
        return userData;
      }), catchError(err => throwError(err)));
  }

  createPassword(organization: any, token: string) {
    return this.httpService.httpPost('/organization/create/pwd/' + token, organization);
  }

  forgotPassword(data: any) {
    return this.httpService.httpPost('/organization/forgot/password', data);
  }

  logout() {
    this.currentUserSubject.unsubscribe();
    localStorage.removeItem('itIsOow');
    // this.router.navigate(['/login']);
    window.location.href = 'https://league.tyfa.app/';
  }

  systemLock() {
    window.location.href = window.location.hostname + '/#/system-locked';
  }
  RedirecToLogin() {
    this.router.navigate(['/login']);
  }
}
