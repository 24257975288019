<div [class.isPrinting]="printService?.isPrinting">
    <!-- <div class="not_connected" *ngIf="!isConnected">Your Internet Connection is not working!</div>
    <router-outlet></router-outlet>
    <router-outlet name="print"></router-outlet>
    <ng-http-loader spinner="sk-double-bounce"></ng-http-loader> -->
    <div class="not_connected" *ngIf="!isConnected">Your Internet Connection is not working!</div>
    <router-outlet></router-outlet>
    <!-- <ng-http-loader [entryComponent]="matSpinner"></ng-http-loader> -->
    <ng-http-loader spinner="sk-double-bounce"></ng-http-loader>
    <router-outlet name="print"></router-outlet>
</div>