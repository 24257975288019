import { OrganizationAuthService } from '.';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpService } from './http.service';
import { Router } from '@angular/router';
@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    constructor(private authenticationService: OrganizationAuthService, private httpService: HttpService, private router: Router) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const userService = new OrganizationAuthService(this.httpService, this.router);
        const isLoggedIn = userService.currentUserValue && userService.currentUserValue.token;
        let authReq: any;
        if (isLoggedIn) {
            authReq = req.clone({
                setHeaders: {
                    'x-tyfa-token': `${userService.currentUserValue.token}`,
                },
            });
        } else {
            const headers = req.headers;
            authReq = req.clone({ headers });
        }
        return next.handle(authReq).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {
                    //                    console.log('HttpResponse::::', evt.body);
                }
            }),
            catchError(err => {
                if (err instanceof HttpErrorResponse) {
                    switch (err.status) {
                        case 403:
                            this.authenticationService.systemLock();
                            break;
                        case 405: // link expired or token deleted.
                            this.authenticationService.RedirecToLogin();
                            break;
                    }
                }
                const error = err.error.message || err.statusText;
                return throwError(err);
            }));

    }
}
