<div class="custom_dialog_content">
    <h2 class="alert_title">{{ title }}</h2>
    <p class="alert_message">{{ message }}</p>
</div>
<div class="custom_dialog_actions">
    <div *ngIf="!reject" class="bottom_buttons secondary">
        <button type="button" mat-stroked-button color="primary" (click)="dialogRef.close()">Cancel</button>
        <button type="button" mat-flat-button color="primary" (click)="dialogRef.close(true)">Confirm</button>
    </div>
    <div *ngIf="reject" class="bottom_buttons secondary">
        <button type="button" mat-flat-button color="primary" (click)="dialogRef.close(true)">OK</button>
    </div>
</div>