<div class="listing">
    <div class="row d-flex flex-wrap">

        <div class="col-xl-3 col-lg-4 col-md-6 col-xs-12 print_col" *ngFor="let row of participants">
            <mat-card class="athlete_card mat-elevation-z1">
                <mat-card-content>
                    <div class="single_athlete">
                        <div class="athlete_photo">
                            <div class="circle_image">
                                <div *ngIf="!row.athlete_hold.isCertified" class="hold_status">Not Certified</div>
                                <div *ngFor="let athhold of row?.athleteexecutiveholds">
                                    <div *ngIf="(athhold?.executivehold?.name==='Disciplinary') && (!athhold?.cleared)"
                                        class="hold_status">Suspended</div>
                                </div>

                                <span class="participant_photo" *ngIf="row.photoURL && !row?.hasCOVID">
                                    <img [src]="row.photoURL" onerror="this.src='../../../../assets/images/user.png';">
                                </span>
                                <span class="participant_photo_default" *ngIf="!row.photoURL || row?.hasCOVID">
                                    <img src="../../../../assets/images/user.png"
                                        onerror="this.src='../../../../assets/images/user.png';" alt="">
                                </span>
                            </div>
                        </div>
                        <div class="athlete_card_details">
                            <h4 class="athlete_name"><a target="_blank"
                                    [routerLink]="['/admin/manage-participants/athlete-profile',row.id]">{{row.firstName}}
                                    {{row.lastName}}</a></h4>
                            <div class="athlete_info">
                                <div class="info_heading">Status</div>
                                <div *ngIf="row.athlete_hold" class="info_desc" [ngClass]="{
                          'certified': row.athlete_hold.isCertified,
                          'not_certified': !row.athlete_hold.isCertified
                        }">{{(row.athlete_hold.isCertified)?'Certified':'Not
                      Certified'}}</div>
                                <div *ngIf="!row.athlete_hold" class="info_desc not_certified">Not Certified</div>
                            </div>
                            <div class="athlete_info">
                                <div class="info_heading">Bracket</div>
                                <div class="info_desc">{{row?.bracket?.description}}</div>
                            </div>
                            <div class="holds print_only">
                                <h5>Holds</h5>
                                <span>{{row.hasHold?'Athlete has holds':'Athlete has no holds'}}</span>
                            </div>
                            <div class="view_only">
                                <h5 *ngIf="row.hasHold">Holds</h5>
                                <mat-chip-list>
                                    <mat-chip color="accent"
                                        *ngIf="(row.athlete_hold)?!row.athlete_hold.isProfileCompleted:true"
                                        selected="true">Profile</mat-chip>
                                    <mat-chip color="accent"
                                        *ngIf="(row.athlete_hold)?!row.athlete_hold.isPhotoUploaded:true"
                                        selected="true">Photo</mat-chip>
                                    <mat-chip color="accent"
                                        *ngIf="(row.athlete_hold)?!row.athlete_hold.isPaymentReceived:true"
                                        selected="true">Payment</mat-chip>
                                    <mat-chip color="accent"
                                        *ngIf="(row?.athlete_hold)?!row.athlete_hold.isBirthCertificate:true"
                                        selected="true">Birth Certificate</mat-chip>
                                    <mat-chip color="accent"
                                        *ngIf="(row?.athlete_hold)?!row.athlete_hold.isContractSigned:true"
                                        selected="true">Contract</mat-chip>
                                    <mat-chip color="accent"
                                        *ngIf="(row?.athlete_hold)?!row.athlete_hold.isHitcheckCompleted:true"
                                        selected="true">HitCheck
                                    </mat-chip>
                                    <div *ngFor="let athleteexecutivehold of row.athleteexecutiveholds">
                                        <mat-chip color="accent" *ngIf="!athleteexecutivehold.cleared && athleteexecutivehold.executivehold.id!==5" selected="true">
                                            {{athleteexecutivehold?.executivehold?.name}}</mat-chip>
                                    </div>
                                </mat-chip-list>
                            </div>

                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>