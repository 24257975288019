import { HttpService } from './http.service';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OrgDashboardService {

  constructor(private httpService: HttpService) { }

  getDashboardData(query?: any) {
    if (query && query.certified) {
      const params = new HttpParams().set('certified', query.certified);
      return this.httpService.httpGet('/organization/dashboard', params);
    } else {
      return this.httpService.httpGet('/organization/dashboard');
    }
  }
  // Here type could be football or cheer
  getAthleteCountByBracket(type: string) {
    return this.httpService.httpGet('/athlete/count/by/bracket?sport=' + type);
  }
  getFootballRoosterAndCertifiedCountByBracket() {
    return this.httpService.httpGet('/athlete-football/count/total-and-certifed');
  }
  getCheerRoosterAndCertifiedCountByBracket() {
    return this.httpService.httpGet('/athlete-cheer/count/total-and-certifed');
  }
  getCheerRoosterAndCertied() {
    return this.httpService.httpGet('/athlete/count/total-and-certifed');
  }
  getVolunteerRosteredAndCleared() {
    return this.httpService.httpGet('/volunteer/rostered-cleard/count');
  }

  getVolunteerWithHolds() {
    return this.httpService.httpGet('/volunteer/listwithholds');
  }

  getAthleteWithHolds() {
    return this.httpService.httpGet('/athlete/listwithholds');
  }

  AssociatedTitlesWithVolunteers(query?: any) {
    let params = new HttpParams();

    if (query.year) {
      params = params.set('year', query.year);
    }
    if (query.season) {
      params = params.set('season', query.season);
    }

    return this.httpService.httpGet('/competition-roster/dashboard/volunteer/totol-associated-titles', params);
  }
}
