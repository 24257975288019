import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintRosterComponent } from './print-roster.component';
import { PrintAthleteComponent } from './print-athlete/print-athlete.component';
import { PrintVolunteerComponent } from './print-volunteer/print-volunteer.component';
import { PrintRosterRoutingModule } from './print-roster-routing.module';
import { MaterialModule } from '../material/material.module';
import { PrintVolunteerTransactionComponent } from './print-volunteer-transaction/print-volunteer-transaction.component';
import { PrintAthleteTransactionComponent } from './print-athlete-transaction/print-athlete-transaction.component';

@NgModule({
  imports: [
    CommonModule,
    PrintRosterRoutingModule,
    MaterialModule
  ],
  declarations: [PrintRosterComponent, PrintAthleteComponent, PrintVolunteerComponent, PrintVolunteerTransactionComponent, PrintAthleteTransactionComponent],
  exports: [PrintRosterComponent, PrintAthleteComponent, PrintVolunteerComponent, PrintVolunteerTransactionComponent, PrintAthleteTransactionComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PrintRosterModule { }
