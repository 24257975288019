import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { SystemConfiguration } from '../../models/system-configuration';
@Injectable({
  providedIn: 'root'
})
export class ManageUserService {

  constructor(private httpService: HttpService) { }

  getSystemConfiguration() {
    return this.httpService.httpGet('/system/configuration');
  }
  getScholasticConfiguration() {
    return this.httpService.httpGet('/system/scholasticsystem');
  }
  createOrUpdateSystemConfiguration(configuration: SystemConfiguration) {
    return this.httpService.httpPost('/system/configuration/createorupdate', configuration);
  }
}
