import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { OrganizationAuthService } from '../services';
import { HttpService } from '../services/http.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private httpService: HttpService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const userService = new OrganizationAuthService(this.httpService, this.router);
    if (userService.currentUserValue && userService.currentUserValue.token) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
