<!-- <mat-nav-list>
    <a (click)="downloadAthletes($event)" mat-list-item>
      <span class="heading" mat-line>Export Athletes</span>
      <span mat-line>Export the list of athletes added in the system.</span>
    </a>
  
    <a (click)="downloadVolunteers($event)" mat-list-item>
      <span class="heading" mat-line>Export Volunteers</span>
      <span mat-line>Export the list of volunteers added in the system.</span>
    </a>
  </mat-nav-list> -->

  <div class="export_container secondary">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
             <div class="export_inner">
                <div class="middle_icon volunteer_icon">
                    <mat-icon>group</mat-icon>
                </div>
                <div class="btn_row">
                    <button type="button" (click)="downloadVolunteers($event)" mat-raised-button color="accent" class="export_button">Export Volunteers</button>
                </div>
                <div class="export_text">Export the list of volunteers<br> added in the system.</div>
             </div>
            </div>
            <div class="col-md-6">
               <div class="export_inner">
                  <div class="middle_icon athlete_icon">
                      <mat-icon>directions_run</mat-icon>
                  </div>
                  <div class="btn_row">
                      <button type="button" (click)="downloadAthletes($event)" mat-raised-button color="primary" class="export_button">Export Athletes</button>
                  </div>
                  <div class="export_text">Export the list of athletes<br> added in the system.</div>
               </div>
            </div>
          </div>
    </div>
  </div>