import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Titles } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class ManageTitleService {

  constructor(private httpService: HttpService) { }

  getAll() {
    return this.httpService.httpGet('/title/all');
  }

  addTitle(title: Titles) {
    return this.httpService.httpPost('/title/create', title);
  }

  updateTitle(title: Titles) {
    return this.httpService.httpPost('/title/update', title);
  }

  changeTitleStatus(title: Titles) {
    return this.httpService.httpPost('/title/change/status', title);
  }

  deleteTitle(titleIds: any) {
    return this.httpService.httpPost('/title/delete', titleIds);
  }

}
