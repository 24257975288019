import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExportRostersRoutingModule } from './export-rosters-routing.module';
import { ExportRostersComponent } from './export-rosters.component';
import { MaterialModule } from '../../material/material.module';

@NgModule({
  imports: [
    CommonModule,
    ExportRostersRoutingModule,
    MaterialModule
  ],
  exports: [
    ExportRostersComponent
  ],
  declarations: [ExportRostersComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class ExportRostersModule { }
