import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material/material.module';
import { ExportRostersComponent } from './organization-manager/export-rosters/export-rosters.component';
import { ExportRostersModule } from './organization-manager/export-rosters/export-rosters.module';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { AuthGuard } from './shared/guard/auth.guard';
import { CommonService } from './shared/services/common.service';
import { DialogsService } from './shared/services/dialogs.service';
import { MomentUtcDateAdapterService } from './shared/services/moment-utc-date-adapter.service';
import { OrgDashboardService } from './shared/services/org-dashboard.service';
import { SpinnerService } from './shared/services/spinner.service';
import { SubDataService } from './shared/services/sub-data.service';
import { CustomValidatorsDirective } from './shared/validators/custom-validator.directive';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatSpinner, MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { AppHttpInterceptor } from './shared/services/AppHttpInterceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PrintRosterModule } from './print-roster/print-roster.module';
// import { PrintLayoutComponent } from './print-layout/print-layout.component';

@NgModule({
  declarations: [
    AppComponent,
    CustomValidatorsDirective,
    ConfirmDialogComponent,
    // PrintLayoutComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    AppRoutingModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    ExportRostersModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    PrintRosterModule
  ],
  exports: [ConfirmDialogComponent],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    DialogsService,
    CommonService,
    AuthGuard,
    SubDataService,
    SpinnerService,
    OrgDashboardService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: DateAdapter, useClass: MomentUtcDateAdapterService },
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
