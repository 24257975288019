import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }

  httpGet(path: string, params?: HttpParams) {
    let param = null;
    if (params) {
      param = params;
    }
    return this.http.get(environment.apiUrl + path, { params: param });
  }

  httpPost(path: string, body: any) {
    const data = JSON.stringify(body);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = { headers: headers };
    return this.http.post(environment.apiUrl + path, data, options);
  }

  httpPut(path: string, body: any) {
    const data = JSON.stringify(body);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = { headers: headers };
    return this.http.put(environment.apiUrl + path, data, options);
  }

  httpDelete(path: string, data: any) {
    let headers: HttpHeaders = new HttpHeaders();
    let param = null;
    headers = headers.append('Content-Type', 'application/json');
    return this.http.request('delete', environment.apiUrl + path, { body: data, headers });

  }
}
