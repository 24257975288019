import { Component, OnInit } from '@angular/core';
import { MatSpinner } from '@angular/material/progress-spinner';
import { SwUpdate } from '@angular/service-worker';
import { PrintService } from './shared/services/print.service';
import { Spinkit, SpinnerVisibilityService } from 'ng-http-loader';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'app';
  public matSpinner = MatSpinner;
  isConnected = true;
  public spinkit = Spinkit;
  constructor(updates: SwUpdate,
    private spinner: SpinnerVisibilityService,
    public printService: PrintService) {
    updates.available.subscribe(event => {
      // this.spinner.hide();
      updates.activateUpdate().then(() => document.location.reload());
    });
  }

  ngOnInit() {

    if (!navigator.onLine) {
      this.isConnected = false;
    } else {
      this.isConnected = true;
    }

  }
}
