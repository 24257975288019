import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SubDataService {
  athleteFilterData = new BehaviorSubject<any>(null);
  volunteerFilterData = new BehaviorSubject<any>(null);
  reloadVolunteer = new BehaviorSubject<any>(null);
  selectedAthleteIds = new BehaviorSubject<any>(null);
  selectedVolunteerIds = new BehaviorSubject<any>(null);
  athletePayment = new BehaviorSubject<any>(null);
  volunteerPayment = new BehaviorSubject<any>(null);
  selectedAthleteParentIds = new BehaviorSubject(null);
  constructor() { }
  setAthleteFilterData(data) {
    this.athleteFilterData.next(data);
  }
  setVolunteerFilterData(data: any) {
    this.volunteerFilterData.next(data);
  }
  setReloadVolunteer(data) {
    this.reloadVolunteer.next(data);
  }
  setSelectedAthleteIds(data) {
    this.selectedAthleteIds.next(data);
  }
  setSelectedVolunteerIds(data) {
    this.selectedVolunteerIds.next(data);
  }
  setSelectedAthleteParentIds(data: any) {
    this.selectedAthleteParentIds.next(data);
  }
  setAthletePayment(data) {
    this.athletePayment.next(data);
  }
  setVolunteerPayment(data) {
    this.volunteerPayment.next(data);
  }

}
